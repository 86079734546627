//
//
//
//
//
//
//
//
//
//
//
//

import { Button } from "element-ui";
export default {
    components: {
        [Button.name]: Button,
    },
    data() {
        return {
            list: [],
        };
    },
    created() {
        this.getInit();
    },
    computed: {
        pages() {
            return this.$store.state.page.pageInfo;
        },
        configInfo() {
            return this.$store.state.base.configInfo;
        },
    },
    methods: {
        async getInit() {
            this.list = this.pages.List;
            document.title = this.configInfo.storeName;
        },
    },
};
